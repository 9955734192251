<template>
    <v-container>
        <v-card class="mb-10">
            <v-card-title class="card-title">Article Form</v-card-title>
        </v-card>
        <v-form :readonly="readOnly">
            <v-row>
                <v-col cols="8">
                    <v-text-field
                        v-model="article.title"
                        label="Title"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="6" lg="4">
                    <date-time-picker
                        :date-label="'Article Date'"
                        :date="articleDateTime"
                        @setDate="setArticleDatetime"
                    />
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="3">
                    <v-file-input
                        v-model="image"
                        class="mt-3"
                        accept="image/*"
                        show-size
                        label="Article Image"
                        :clearable="clearable"
                        @change="changeImage"
                        @click:clear="clearImage()"
                    />
                </v-col>
                <v-col cols="9">
                    <v-img
                        v-if="image"
                        width="300px"
                        height="auto"
                        contain
                        :src="imagePath"
                    />
                </v-col>
                <v-col cols="3">
                    <v-file-input
                        v-model="file"
                        class="mt-3"
                        accept="pdf/*"
                        show-size
                        label="Article File"
                        :clearable="clearable"
                        @change="changeFile"
                        @click:clear="clearFile()"
                    />
                </v-col>
                <v-col cols="3">
                    <v-btn
                        class="mx-2 white--text"
                        large
                        color="black"
                        @click="uploadFile()"
                    >
                        Upload
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    {{ fileUrl }}
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="12">
                    <editor
                        :id="`article_content`"
                        v-model="article.content"
                        :api-key="tinymceApiKey"
                        :init="editorInit"
                    />
                </v-col>
            </v-row>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn :loading="loading" class="mx-2 white--text" :disabled="shouldDisableSubmit()" large color="black" @click="onSubmit">
                Submit
            </v-btn>
            <v-btn class="mx-2 white--text" large color="red" @click="$router.go(-1)">
                Cancel
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import articleService from '@/services/article'
    import uploadService from '@/services/upload'
    import Alert from '@/components/Alert'
    import DateTimePicker from '@/components/DateTimePicker'
    import momentTimezone from 'moment-timezone'
    import Editor from '@tinymce/tinymce-vue'
    export default {
        name: 'ArticleForm',
        components: { Alert, DateTimePicker, Editor },
        data: () => {
            return {
                showAlert: false,
                message: null,
                type: null,
                mode: null,
                googleApiHost: 'https://storage.googleapis.com',
                readOnly: false,
                clearable: true,
                loading: false,
                startDate: null,
                endDate: null,
                fileUrl: '',
                article: {
                },
                articleId: null,
                rules: [
                    v => !!v || 'Field is required'
                ],
                image: {
                    name: 'Image file'
                },
                imageChanged: false,
                imagePath: '',
                file: {
                    name: 'File'
                },
                filePath: '',
                articleDateTime: null,
                editorInit: {
                    images_upload_handler: function(file, success) {
                        let formData = new FormData()
                        formData.append('file', file.blob(), file.filename())
                        uploadService.uploadFile(formData).then((response) => { success(process.env.VUE_APP_GOOGLE_STORAGE_API + '/' + response.data.content) })
                    },
                    menubar: false,
                    min_height: 600,
                    plugins: [
                        'advlist autolink lists charmap print preview anchor',
                        'searchreplace visualblocks fullscreen',
                        'insertdatetime table paste code help wordcount link',
                        'image code media'
                    ],
                    automatic_uploads: true,
                    powerpaste_allow_local_images: true,
                    file_picker_types: 'image',
                    toolbar: 'undo redo | styleselect | forecolor | bold italic | fontselect | emoticons | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link unlink | image media | code '
                },
                tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY
            }
        },
        async created() {
            this.mode = this.$route.params.mode
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.articleId = this.$route.params.articleId
            if (this.articleId) {
                articleService.getArticle(this.articleId).then(resp => {
                    this.article = resp.data
                    this.articleDateTime = momentTimezone(this.article.date).tz('Europe/Athens').format('YYYY-MM-DD').toString()

                    if (this.article.image) {
                        this.imagePath = this.googleApiHost + '/' + this.article.image
                    }
                })
            }
        },
        methods: {
            changeFile() {
                this.fileChanged = true
                if (this.file) {
                    this.filePath = URL.createObjectURL(this.file)
                }
            },
            clearFile() {
                this.filePath = ''
            },
            changeImage() {
                this.imageChanged = true
                if (this.image) {
                    this.imagePath = URL.createObjectURL(this.image)
                }
            },
            clearImage() {
                this.imagePath = ''
                this.article.image = null
            },
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            shouldDisableSubmit() {
                return false
            },
            setArticleDatetime(date) {
                this.articleDateTime = date
            },
            onEdit() {
                this.$router.push(`/articles/${this.articleId}/edit`)
            },
            createArticle() {
                articleService.createArticle(this.article).then(articleResponse => {
                    if (articleResponse.id) {
                        this.articleId = articleResponse.id
                    }
                    this.loading = false
                    this.$router.push(`/articles`)
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            editArticle() {
                articleService.updateArticle(this.articleId, this.article).then(articleResponse => {
                    if (articleResponse.id) {
                        this.articleId = articleResponse.id
                    }
                    this.loading = false
                    this.$router.push(`/articles`)
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            uploadFile() {
                if (!this.fileChanged || this.file == null) {
                    return
                }
                let formData = new FormData()
                formData.append('file', this.file)
                uploadService.uploadFile(formData).then((response) => { this.fileUrl = this.googleApiHost + '/' + response.data.content })
            },
            uploadImage() {
                if (!this.imageChanged || this.image == null) {
                    return
                }
                let formData = new FormData()
                formData.append('file', this.image)
                return articleService.uploadFile(formData)
            },
            async onSubmit() {
                this.loading = true
                this.article.date = momentTimezone(this.articleDateTime).tz('Europe/Athens').utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]').toString()

                const uploadImageResponse = await this.uploadImage()
                const imageUrl = uploadImageResponse?.data.content || null
                if (imageUrl) {
                    this.article.image = imageUrl
                }
                if (this.mode === 'edit') {
                    this.editArticle()
                } else if (this.mode === 'create') {
                    this.createArticle()
                }
            }
        }

    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
